<template>
  <v-card :loading="loading">
      <v-card-title class="text-h4 font-weight-bold">เพิ่มข้อมูลลูกค้า<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="text-left pt-3">
             <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense class="ma-0 pa-0">
              <v-col cols="12">
                <TextField :title="'ชื่อลูกค้า'" v-model="tenantName" :rules="nameRules" :required="true"/>
              </v-col>
               <v-col cols="12">
                <TextField :title="'รหัสลูกค้า'" v-model="customerId" :rules="idRules" :required="true"/>
              </v-col>
                <v-col cols="12">
                <TextField :title="'InstanceName'" v-mask="'AAAAAAAAAA'" v-model="instanceName" :rules="idRules" :required="true"/>
              </v-col>
               <v-col cols="12">
                 <label class="text-title-4 natural--text text--darken-4"> รูปแบบแพ็กเกจ <span class="error--text">*</span>
                   <v-radio-group
      v-model="packageType"
      row
      :rules="packageTypeRules"
    >
      <v-radio
        label="Paid tier"
        value="paid"
      ></v-radio>
      <v-spacer/>
      <v-radio
        label="Free tier"
        value="free"
      ></v-radio>
       <v-spacer/>
    </v-radio-group>
                 </label>
               </v-col>
              <v-col cols="12">
                <Select :items="packageItems"   :rules="packageRules" v-if="packageType==='paid'" :title="'แพ็คเกจ'" :required="true" :placeholder="'เลือกแพ็คเกจ'"  v-model="type" item-text="text" item-value="value"/>
              </v-col>
          </v-row>
             </v-form>
      </v-card-text>
      <v-card-actions>
       <v-spacer/>
<v-btn width="144"  text color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn>
<v-btn width="144"   color="primary" large @click="submit"><span class="text-title-2">{{action}}</span></v-btn>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import TextField from '@/components/common/TextField'
import Select from '@/components/common/Select'
import { mask } from 'vue-the-mask'

export default {
  directives: { mask },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean
    },
    value: {
      type: Object
    }
  },
  components: {
    TextField,
    Select
  },
  watch: {
    status () {
      this.$refs.form.reset()
    }
  },
  data () {
    return {
      typeItems: [],
      tenantName: null,
      customerId: null,
      type: null,
      loading: false,
      packageType: null,
      valid: false,
      packageRules: [v => !!v || 'กรุณาเลือกแพ็กเกจ'],
      packageTypeRules: [v => !!v || 'กรุณาเลือกรูปแบบแพ็กเกจ'],
      nameRules: [
        v => !!v || 'กรุณาระบุชื่อลูกค้า'
      ],
      idRules: [
        v => !!v || 'กรุณาระบุรหัสลูกค้า'
      ],
      packageItems: [
        { text: 'Population Package 50,000', value: 'Population Package 50,000' },
        { text: 'Device Package', value: 'Device Package' }
      ],
      instanceName: null
    }
  },
  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        this.$emit('change', { tenantName: this.tenantName, isFreeTier: this.packageType === 'free', subscriptionPackage: (this.packageType === 'free') ? 'Free package' : this.type, instanceName: this.instanceName, customerId: this.customerId })
        this.$emit('onSubmit')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
