<template>
  <v-container fluid>
    <TitleBar :title="'ข้อมูลลูกค้า'" :subtitle="''">
      <template v-slot:action>
          <v-btn v-if="$auth.role.isSystemAdmin" large color="primary" @click="createTenantDialg=true"><v-icon>mdi-plus</v-icon><span class="text-title-2">เพิ่มข้อมูลลูกค้า</span></v-btn>
      </template>
    </TitleBar>
    <FilterBar>
      <template v-slot:left>
         <v-col cols="12" md="4" >
          <TextField v-model="search" :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ,ผู้สร้าง,อื่นๆ"/>
         </v-col>
      </template>
      <template v-slot:right>
      </template>
    </FilterBar>
    <DataTable :headers="headers" :loading="loading" :search="search" :items="items">
       <template v-slot:item.action="{ item }">
       <v-btn icon :to="{ name: 'tenantedit', params: { tenantId: item.tenantId } }"><v-icon>mdi-pencil</v-icon></v-btn>
       </template>
    </DataTable>
       <v-dialog v-model="createTenantDialg" persistent width="529">
         <CreateTenantCard @onClose="createTenantDialg = false" @onSubmit="createTenant" :action="'เพิ่ม'" v-model="tenant" :status="createTenantDialg"/>
       </v-dialog>
  </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import TextField from '@/components/common/TextField'
import FilterBar from '@/components/common/FilterBar'
import DataTable from '@/components/common/DataTable'
import CreateTenantCard from '@/components/tenant/CreateTenantCard'
import { getTenant, postTenant } from '@/api/'

export default {
  components: {
    TitleBar,
    TextField,
    FilterBar,
    DataTable,
    CreateTenantCard
  },
  data () {
    return {
      headers: [
        { text: 'รหัสลูกค้า', value: 'customerId', class: 'primary lighten-5' },
        { text: 'ชื่อลูกค้า', value: 'tenantName', class: 'primary lighten-5' },
        { text: 'แพ็กเกจ', value: 'subscriptionPackage', class: 'primary lighten-5' },
        { text: 'เริ่มต้น', value: 'startDate', class: 'primary lighten-5' },
        { text: 'สิ้นสุด', value: 'endDate', class: 'primary lighten-5' },
        { text: 'Action', value: 'action', class: 'primary lighten-5' }
      ],
      loading: false,
      items: [],
      search: null,
      createTenantDialg: false,
      tenant: {}
    }
  },
  methods: {
    createTenant () {
      console.log(this.tenant)
      postTenant(this.tenant, message => {
        if (message.data.code === 1) {
          this.fetchData()
          this.createTenantDialg = false
        }
      }, error => {
        console.log(error)
      })
    },
    fetchData () {
      if (this.$auth.role.isSystemAdmin) {
        this.loading = true
        getTenant({
          tenantId: '',
          tenantName: ''
        }, message => {
          if (message.data.code === 1) {
            if (message.data.result) {
              // console.log(message.data.result)
              this.items = message.data.result.items
            } else {
              this.items = []
            }
          }
          this.loading = false
        }, error => {
          this.loading = false
          console.log(error)
        })
      } else {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        getTenant({
          tenantId: tenant.tenantId,
          tenantName: ''
        }, message => {
          if (message.data.code === 1) {
            if (message.data.result) {
              // console.log(message.data.result)
              this.items = message.data.result.items
            } else {
              this.items = []
            }
          }
          this.loading = false
        }, error => {
          this.loading = false
          console.log(error)
        })
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }

}
</script>
